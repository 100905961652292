<template>
    <b-card
      title=""
      class="mb-2 edit-shop_item-container"
      ref="edit-shop_item"
      id="edit-shop_item"
      hide-footer

    >
      <div>
        <Confirm
          :title="$t('COMMON.CONFIRM')"
          :message="$t('PAGES.SHOPITEM.CONFIRM_DELETE')"
          ref="deleteItemConfirm"
          type="success"
          @confirm="onDeleteItemConfirm"
        />

        <b-form @submit="updateShopItem" class="mt-8">
          <div class="mt-4 mb-4" v-if="!form.is_ticket">
            <b-button type="button" variant="warning" v-if="form.shop_item_id" @click="duplicateToAllCompanies">{{$t('PAGES.SHOPITEM.CREATE_FOR_ALL')}}</b-button>
          </div>

          <div class="mb-8">
            <required-fields-info/>
          </div>

          <b-form-group id="input-group-shop_item_id" label="ID" label-for="input-shop_item_id">
            <b-form-input
              id="input-shop_item_id"
              v-model="form.shop_item_id"
              type="text"
              disabled
            />
          </b-form-group>

          <div v-if="isTHS" class="d-flex align-items-center mt-6">
            <label class="checkbox checkbox-outline checkbox-success">
              <input type="checkbox" name="is_shared" v-model="form.is_shared" />
              <span></span>
            </label>
            <span class="ml-3 cursor-pointer">{{ $t('PAGES.SHOPITEM.IS_SHARED') }}</span>
          </div>

          <h5 class="mb-8 mt-8">{{$t('PAGES.SHOPITEM.PRESENTATION')}}:</h5>
          <b-form-group id="input-group-name" :label="$t('PAGES.SHOPITEM.NAME')" label-for="input-name">
            <b-form-input id="input-name" v-model="form.name" type="text"></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-descr" :label="$t('PAGES.SHOPITEM.DESCR')" label-for="input-descr">
            <b-form-textarea
              id="input-descr"
              v-model="form.descr"
              size="sm"
              :placeholder="$t('PAGES.SHOPITEM.DESCR')"
            />
          </b-form-group>

          <b-form-group id="input-group-descr" :label="$t('PAGES.SHOPITEM.PRIORITY')" label-for="input-priority">
            <b-form-input
              id="input-priority"
              v-model="form.priority"
              size="sm"
            />
          </b-form-group>

          <h5 class="mb-8 mt-8">{{$t('PAGES.SHOPITEM.IMAGE')}}</h5>

          <b-row class="ml-1" v-if="form.img_url !== ''">
            <b-col lg="12"
              ><b-img
                style="max-height: 300px;"
                :src="form.img_url"
                fluid
                thumbnail
              ></b-img
            ></b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col lg="12"
              ><b-button type="button" variant="success" @click="selectFile()"
                >{{$t('PAGES.SHOPITEM.UPLOAD_IMAGE')}}</b-button
              ></b-col
            >
          </b-row>

          <input
            hidden
            type="file"
            id="file"
            ref="file"
            v-on:change="handleFileUpload()"
            accept="image/*"
          />

          <h5 class="mb-8 mt-8">{{$t('PAGES.SHOPITEM.PRICE_SEK')}}:</h5>

          <b-form-group :label="$t('PAGES.SHOPITEM.CURRENCY')" label-for="form-currency">
           <b-form-select
              id="form-currency"
              v-model="form.currency"
              :options="currencyOptions"
            />
          </b-form-group>



          <b-form-group
            id="input-group-amount"
            :label="$t('PAGES.SHOPITEM.AMOUNT')"
            label-for="input-amount"
          >
            <b-form-input
              id="input-amount"
              v-model="form.amount"
            />
          </b-form-group>

          <b-form-group :label="$t('PAGES.SHOPITEM.VAT_PCT')" label-for="form-vat_pct">
            <b-form-select
              id="form-vat_pct"
              v-model="form.vat_pct"
              :options="vatPctOptions"
              @change="calc_amount_vat"
            />
          </b-form-group>

          <b-form-group id="input-group-amount_vat" :label="$t('PAGES.SHOPITEM.AMOUNT_VAT')" label-for="input-amount_vat">
            <b-form-input id="input-amount_vat" v-model="form.amount_vat" type="text"></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-ex_vat" :label="$t('PAGES.SHOPITEM.EX_VAT')" label-for="input-ex_vat">
            <b-form-input disabled id="input-ex_vat" v-model="ex_vat" type="text"></b-form-input>
          </b-form-group>

          <h5 class="mb-8 mt-8">{{$t('PAGES.SHOPITEM.ACCOUNTING')}}:</h5>
          <b-form-group
            id="input-group-book_acct_article"
            :label="$t('PAGES.SHOPITEM.BOOK_ACCT_ARTICLE')"
            label-for="input-book_acct_article"
          >
            <b-form-input
              id="input-book_acct_article"
              v-model="form.book_acct_article"
              type="text"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-book_acct_vat"
            :label="$t('PAGES.SHOPITEM.BOOK_ACCT_VAT')"
            label-for="input-book_acct_vat"
          >
            <b-form-input
              id="input-book_acct_vat"
              v-model="form.book_acct_vat"
              type="text"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-book_place"
            :label="$t('PAGES.SHOPITEM.BOOK_PLACE')"
            label-for="input-book_place"
          >
            <b-form-input
              id="input-book_place"
              v-model="form.book_place"
              type="text"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-book_project"
            :label="$t('PAGES.SHOPITEM.PROJECT')"
            label-for="input-book_project"
          >
            <b-form-input
              id="input-book_project"
              v-model="form.book_project"
              type="text"
            ></b-form-input>
          </b-form-group>

          <div class="d-flex align-items-center mb-6">
            <b-input-group class="mb-2">
              <b-input-group-prepend is-text>
                <label class="checkbox checkbox-sm checkbox-outline checkbox-success">
                  <input
                    type="checkbox"
                    aria-label="Checkbox for is_shared"
                    v-model="form.is_shared"
                    @click="form.is_shared = !form.is_shared"
                  />
                  <span></span>
                </label>
              </b-input-group-prepend>
              <b-input-group-prepend is-text>{{$t('PAGES.SHOPITEM.MIN_AGE')}}</b-input-group-prepend>
              <b-form-input

                min="0"
                max="200"
                placeholder="0"
                v-model="form.rules.age_min"
                :disabled="!check_join[0]"
              ></b-form-input>
            </b-input-group>
          </div>

          <!--<div>
            <h5 class="mb-8 mt-8">{{$t('PAGES.SHOPITEM.RECEIVER')}}:</h5>
            <div>
              <label class="radio radio-outline radio-success mb-4">
                <input type="radio" v-model="form.receiver_type" value="COMPANY" name="reciever_type" />
                <span style="margin-right: 12px;"></span>
                {{$t('PAGES.SHOPITEM.RECEIVER_OPTION1')}}
              </label>
              <label class="radio radio-outline radio-success mb-4">
                <input type="radio" v-model="form.receiver_type" value="MEMBER" name="reciever_type" />
                <span style="margin-right: 12px;"></span>
                {{$t('PAGES.SHOPITEM.RECEIVER_OPTION2')}}
              </label>
            </div>
            <div v-if="form.receiver_type==='COMPANY'">
              <b-form-group class="col-lg-4 col-sm-12 px-0 py-0" id="group-calculation-method" :label="$t('PAGES.SHOPITEM.QUANTITY_METHOD')" label-for="field-calculation-method">
                <b-form-select
                  id="field-calculation-method"
                  v-model="form.quantity_type"
                  :options="[{value: 'QUANTITY', text: $t('PAGES.SHOPITEM.QUANTITY_OPTION1')}, { value: 'MEMBERS', text: $t('PAGES.SHOPITEM.QUANTITY_OPTION2')}]"/>
              </b-form-group>
            </div>
          </div>-->


          <div v-if="!is_ticket">
            <h5 class="mb-8 mt-8">{{$t('PAGES.SHOPITEM.MEMBERSHIP_SETTINGS')}}:</h5>

            <b-form-group
              id="send_email_template_id-group"
              label="Skicka E-post vid köp"
              label-for="send_email_template_id">
              <b-form-select
                id="send_email_template_id"
                v-model="form.email_template_id"
                :options="templateOptions"/>
            </b-form-group>

            <b-form-group id="member_type_id-group" :label="$t('PAGES.SHOPITEM.MEMBER_TYPE_ID')" label-for="member_type_id">
              <b-form-select
                id="member_type_id"
                v-model="form.member_type_id"
                :options="memberTypeOptions"
              />
            </b-form-group>

            <div class="d-flex align-items-center mb-6">
              <b-input-group class="mb-2">
                <b-input-group-prepend is-text>
                  <label class="checkbox checkbox-sm checkbox-outline checkbox-success">
                    <input
                      type="checkbox"
                      aria-label="Checkbox for following text input"
                      v-model="check_join[0]"
                    />
                    <span></span>
                  </label>
                </b-input-group-prepend>
                <b-input-group-prepend is-text>{{$t('PAGES.SHOPITEM.MIN_AGE')}}</b-input-group-prepend>
                <b-form-input
                  v-model="form.rules.age_min"
                  min="0"
                  max="200"
                  placeholder="0"
                  :disabled="!check_join[0]"
                />
              </b-input-group>
            </div>

            <div class="d-flex justify-content-between mb-6">
              <b-input-group class="mb-2">
                <b-input-group-prepend is-text>
                  <label class="checkbox checkbox-sm checkbox-outline checkbox-success">
                    <input
                      v-model="check_join[1]"
                      type="checkbox"
                      aria-label="Checkbox for following text input"
                    />
                    <span></span>
                  </label>
                </b-input-group-prepend>

                <b-input-group-prepend is-text>
                  {{$t('PAGES.SHOPITEM.MAX_AGE')}}
                </b-input-group-prepend>
                <b-form-input
                  min="0"
                  max="200"
                  placeholder="0"
                  v-model="form.rules.age_max"
                  :disabled="!check_join[1]"
                />
              </b-input-group>
            </div>

            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="checkbox checkbox-outline checkbox-success">
                  <input
                    type="checkbox"
                    name=""
                    v-model="form.is_active"
                    @click="form.is_active = !form.is_active"
                  />
                  <span></span>
                </label>
                <span class="ml-3 cursor-pointer">{{ $t('PAGES.SHOPITEM.IS_ACTIVE') }}</span>
              </div>

              <div class="d-flex align-items-center mt-6">
                <label class="checkbox checkbox-outline checkbox-success">
                  <input type="checkbox" name="is_member_fee" v-model="form.is_member_fee" />
                  <span></span>
                </label>
                <span class="ml-3 cursor-pointer">{{ $t('PAGES.SHOPITEM.IS_MEMBER_FEE') }}</span>
              </div>


              <div class="d-flex align-items-center mt-6">
                <label class="checkbox checkbox-outline checkbox-success">
                  <input type="checkbox" name="is_extra" v-model="form.is_extra" />
                  <span></span>
                </label>
                <span class="ml-3 cursor-pointer">{{ $t('PAGES.SHOPITEM.IS_EXTRA') }}</span>
              </div>

              <div class="d-flex align-items-center mt-6" v-if="form.is_member_fee">
                <label class="checkbox checkbox-outline checkbox-success">
                  <input type="checkbox" name="is_family" v-model="form.is_family" />
                  <span></span>
                </label>
                <span class="ml-3 cursor-pointer">{{ $t('SHOP_ITEM.IS_FAMILY') }}</span>
              </div>

              <div v-if="form.is_member_fee" class="d-flex align-items-center mt-6">
                <label class="checkbox checkbox-outline checkbox-success">
                  <input type="checkbox" name="is_default" v-model="form.is_default" />
                  <span></span>
                </label>
                <span class="ml-3 cursor-pointer">{{ $t('SHOP_ITEM.IS_DEFAULT', { company_name: company_name }) }}</span>
              </div>
            </b-form-group>


            <div>
              <div>
                <label class="radio radio-outline radio-success mb-4">
                  <input type="radio" v-model="form.period_type" value="DAYS" name="type" />
                  <span style="margin-right: 12px;"></span>
                  {{ $t('SHOP_ITEM.DAYS') }}

                </label>
                <label class="radio radio-outline radio-success mb-4">
                  <input type="radio" v-model="form.period_type" value="PERIODS" name="type" />
                  <span style="margin-right: 12px;"></span>
                  {{ $t('SHOP_ITEM.PERIODS') }}

                </label>
                <label class="radio radio-outline radio-success mb-4">
                  <input type="radio" v-model="form.period_type" value="RANGE" name="type" />
                  <span style="margin-right: 12px;"></span>
                  {{ $t('SHOP_ITEM.RANGE') }}

                </label>
              </div>

              <div class="ml-0">
                <b-form-group
                  id="input-group-meta-num_days"
                  label="Antal dagar"
                  label-for="input-meta-num_days"
                  v-if="form.period_type === 'DAYS'"
                >
                  <b-form-input
                    id="input-meta-num_days"
                    v-model="form.num_days"
                  />
                </b-form-group>

                <ShopItemPeriodsTable
                  class="mt-8"
                  ref="periodsTable"
                  v-if="form.period_type === 'PERIODS'"
                  @selectedPeriodIdsChanged="selectedPeriodIdsChanged"
                  @toastr="toastr"
                />

                <b-form-group
                  id="input-group-meta-from_date"
                  :label="$t('SHOP_ITEM.FROM_DATE')"
                  label-for="input-meta-from_date"
                  v-if="form.period_type === 'RANGE'"
                >
                  <memlist-date-picker v-model="form.from_date" />
                </b-form-group>

                <b-form-group
                  id="input-group-meta-to_date"
                  :label="$t('SHOP_ITEM.TO_DATE')"
                  label-for="input-meta-to_date"
                  v-if="form.period_type === 'RANGE'"
                >
                  <memlist-date-picker v-model="form.to_date" />
                </b-form-group>

                <b-form-group
                  id="next-membership-group"
                  :label="$t('SHOP_ITEM.NEXT_SHOP_ITEM_ID')"
                  label-for="next_shop_item_id"
                >
                  <b-form-select
                    id="next_shop_item_id"
                    v-model="form.next_shop_item_id"
                    :options="nextShopItemIdOptions"
                  />
                </b-form-group>

                <b-form-group
                  id="next-period-group"
                  :label="$t('SHOP_ITEM.NEXT_PERIOD_ID')"
                  label-for="next_period_id"
                >
                  <b-form-select
                    id="next_period_id"
                    v-model="form.next_period_id"
                    :options="periodOptions"
                  />
                </b-form-group>

                <b-form-group
                  v-if="form.next_period_id"
                  id="create-for-next-period-group"
                  :label="$t('SHOP_ITEM.CREATE_FOR_NEXT')"
                  label-for="create_for_next_period"
                >
                  <b-form-input
                    id="input-next_name"
                    v-model="next_name"
                  />
                  <b-button @click="copyToNextPeriod" variant="success">{{$t('PAGES.SHOPITEM.COPY_TO_NEXT_PERIOD')}}</b-button>
                </b-form-group>
              </div>
            </div>

          </div>

          <RightSaveButton
            ref="save-button"
            :text="form.is_ticket ? $t('PAGES.SHOPITEM.SAVE_TICKET') : $t('PAGES.SHOPITEM.SAVE')"
            @clicked="save_data()"
          />

        </b-form>

      </div>
    </b-card>
</template>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";

.select2-container  {
  width: 100% !important;
}
.select2-container--default {
  width: 100% !important;
}
</style>

<script>
import 'vue-select/dist/vue-select.css';
import Select2 from 'v-select2-component';

import axios from 'axios';
import { mapGetters } from 'vuex';
import ShopItemPeriodsTable from '@/view/pages/ml/shopitem/ShopItemPeriodsTable.vue';
import Confirm from '@/view/components/Confirm.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import TipLabel from '@/view/components/TipLabel.vue';
import RequiredFieldsInfo from '@/view/components/RequiredFieldsInfo.vue';

import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';
import { useVuelidate } from '@vuelidate/core';

export default {
  name: 'ShopItemEdit',

  components: {
    Select2,
    ShopItemPeriodsTable,
    Confirm,
    TipLabel,
    RequiredFieldsInfo,
    RightSaveButton
  },

  mixins: [ toasts ],

  setup() {
    return { v$: useVuelidate() };
  },

  directives: {
    numeric: {
      inserted: (el) => {
        el.oninput = (ev) => {
          const value = parseFloat(ev.target.value);
          el.value = isNaN(value) ? '' : value;
        };
      }
    }
  },

  computed: {
    company_name() {
      return this.companies.find(c => c.company_id === this.currentCompanyId).name;
    },
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'reg_only', 'companies']),
    nextShopItemIdOptions() {
      return [
        {
          text: 'Ej satt',
          value: null
        },
        ...this.shop_items
          .filter(
            shop_item =>
              shop_item.is_member_fee &&
              (!shop_item.is_days ? shop_item.shop_item_id !== this.form.shop_item_id : true)
          )
          .map(item => {
            return {
              text: item.name,
              value: item.shop_item_id
            };
          })
      ];
    }
  },
  mounted() {
    if (this.templates) {
      this.local_templates = [ ...this.templates ];
    }
    const loader = this.$loading.show();

    this.load_instrument_types();

    if (this.periods) {
      this.populatePeriodOptions();
    }

    loader && loader.hide();
  },
  data() {
    return {
      local_templates: [],
      next_name: null,
      ex_vat: 0,
      currencyOptions: [
        { value: 'SEK', text: 'SEK' },
        { value: 'EUR', text: 'EUR' },
        { value: 'USD', text: 'USD' }
      ],
      vatPctOptions: [
        { value: 0.0, text: this.$t('PAGES.SHOPITEM.VAT_FREE') },
        { value: 6.0, text: '6%' },
        { value: 12.0, text: '12%' },
        { value: 25.0, text: '25%' },
      ],
      instrument_types: [],
      toDelete: null,
      apiKeyNotFound: false,
      templateOptions: [],
      myValue: '',
      myOptions: [],
      file: {
        name: 'bild0.jpg'
      },

      form: {
        currency: 'SEK',
        is_default: false,
        shop_item_id: '',
        company_id: '',
        name: '',
        descr: '',
        period_ids: [],
        frontend: {
          img_url: '',
          reg_only: 0
        },
        is_active: false,
        discounts: {
          pct: 0,
          amount: 0,
          reason: ''
        },
        book_acct_vat: 0,
        book_acct_article: 0,
        book_place: '',
        book_project: '',
        book_tags: [],

        rules: { age_min: 0, age_max: 0 },
        amount: 0,
        amount_vat: 0,
      },
      test_days: true,
      tagOptions: [],
      creating: false,
      editing: false,
      shop_item: '',
      reg_only_true: true,
      check_join: [false, false],
      item_type: 'reg_only_true',
      periodOptions: []
    };
  },
  props: ['memberTypeOptions','templates','is_ticket','shop_item_id','shop_items','isTHS'],
  emits: ['onShopItemCreated', 'onShopItemUpdated', 'onDeleteShopItem', 'onCloseClicked'],
  watch: {
    templates: {
      deep: true,
      handler(val) {
        this.local_templates = [ ...val ];
      }
    },
    'form.name'(newValue) {
      this.next_name = newValue;
    },
    'form.period_type'(newValue) {
      if (newValue === 'PERIODS') {
        this.setPeriodsTable(this.form.period_ids);
      }
    },
    '$store.state.periods': function() {
    },
    shop_item_id(newVal, oldVal) {
      if (newVal) {
        this.selectShopItem(newVal);
      }
    },
    check_join(newVal, oldVal) {
      if (!newVal[0]) this.form.rules.age_min = null;
      if (!newVal[1]) this.form.rules.age_max = null;
    },
    item_type(newVal, oldVal) {
      this.reg_only_true = newVal === 'reg_only_true';
      this.member_fee = newVal === 'member_fee';
    },
    periods() {
      this.populatePeriodOptions();
    }
  },

  methods: {

    validate_state(field, state) {
      const result = (state.dirty || state.validated) ? !state.errors[0] : null;
      return result;
    },

    copyToNextPeriod() {
      const loader = this.$loading.show();

      const copy = {};

      for (const k in this.form) {
        copy[k] = this.form[k];
      }

      copy.name = this.next_name;
      copy.period_id = this.form.next_period_id;
      copy.period_ids = [this.form.next_period_id];
      copy.next_period_id = null;
      copy.next_shop_item_id = null;
      copy.copy_from_shop_item_id = this.form.shop_item_id;

      axios
        .post('/shop_item', copy)
        .then(res => {
          loader && loader.hide();
          if (res.status === 201) {
            this.creating = false;
            this.form = res.data;

            this.$emit('onShopItemCreated', this.form);

            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SHOPITEM.COPIED'));
          }
        })
        .catch(err => {
          loader && loader.hide();
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SHOPITEM.UNABLE_COPY'));
        });
    },
    populatePeriodOptions() {
      this.periodOptions = [];

      for (const p of this.periods) {
        this.periodOptions.push({
          value: p.id,
          text: p.name
        });
      }
    },

    calc_amount_vat() {
      const val = parseFloat(this.form.amount+'');
      if (isNaN(val)) {
        this.form.amount_vat = 0.0;
        return;
      }

      const vat_pct = parseFloat(this.form.vat_pct+'');

      if (vat_pct == 0.0) {
        this.form.amount_vat = 0;
        return;
      }

      this.form.amount_vat = (Math.round((val - (val / (1 + (this.form.vat_pct / 100.0)))) * 100.0)) / 100.0;
      this.ex_vat = Math.round((val - this.form.amount_vat)*100)/100.0;
    },

    duplicateToAllCompanies() {
      const loader = this.$loading.show();

      axios
        .post(`/shop_item/duplicate/${this.form.shop_item_id}`)
        .then(res => {

          if (res.status === 201) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SHOPITEM.DUPLICATED'));
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SHOPITEM.UNABLE_DUPLICATE'));
          }

          loader && loader.hide();
        })
        .catch(err => {
          loader && loader.hide();
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SHOPITEM.UNABLE_DUPLICATE'));
        });
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];

      if (this.file.size > (50000000)) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.MUST_BE_LESS'));
        return;
      }

      this.uploadFile();
    },
    selectFile() {
      this.$refs.file.click();
    },
    uploadFile() {
      const loader = this.$loading.show();

      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('shop_item_id', this.form.shop_item_id);
      formData.append('file_source', 'shop_items');
      formData.append('file_type', 'FILE_IMAGE');
      formData.append('form_shop_item_id', this.form.shop_item_id);
      formData.append('company_id', this.currentCompanyId);
      axios
        .post('/fileupload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          loader && loader.hide();
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SHOPITEM.IMAGE_UPLOADED'));

          this.form.img_url = res.data.fileobjs[0].public_path;
        })
        .catch(err => {
          loader && loader.hide();
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SHOPITEM.UNABLE_IMAGE_UPLOAD'));
        });
    },

    checkAPISetup() {
      axios
        .get(`/api_key/service?service=dintero&company_id=${this.currentCompanyId}`)
        .then(res => {
          if (res.status === 404) {
            this.apiKeyNotFound = true;
          } else {
            this.apiKeyNotFound = false;
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta API nyckeldata');
        });
    },

    async loadTemplates() {
      if (!this.currentCompanyId) {
        return;
      }

      this.local_templates = [];
      this.templateOptions = [];
      axios
        .get(`/template?company_id=${this.currentCompanyId}`)
        .then(res => {
          if (res.status === 200) {
            this.local_templates = res.data;

            this.local_templates.forEach(t => {
              this.templateOptions.push({ text: t.name, value: t.template_id });
            });
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte lista artiklar');
        });
    },

    async load_instrument_types() {

      this.instrument_types = [];
      axios
        .get(`/instrument_type`)
        .then(res => {
          if (res.status === 200) {
            this.instrument_types = res.data.map(item => ({ value: item.id, text: item.name }));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte lista instrumenttyper');
        });
    },

    onCloseClicked() {
      this.$emit('onCloseClicked');
    },
    tagChanged(ee) {},
    tagSelected(ee) {},
    async loadData() {
      if (!this.currentCompanyId) {
        return;
      }
      if (!this.titles) {
        return;
      }

    },
    createShopItem() {

      this.resetForm();
      this.creating = true;
      this.editing = true;

      this.scrollToEditShopItem();

      this.form.company_id = this.currentCompanyId;
      this.form.period_id = this.currentPeriodId;
      this.form.frontend.reg_only = true;
      this.form.is_ticket = this.is_ticket;
      this.form.is_member_fee = false;
      this.form.is_family = false;
      this.form.is_default = false;
      this.form.period_ids = [];

    },
    onDeleteItemConfirm() {
      this.deleteShopItem(this.toDelete);
      this.toDelete = null;
    },
    deleteShopItemClicked(shop_item_id) {
      this.toDelete = shop_item_id;
      this.$refs['deleteItemConfirm'].show();
    },
    deleteShopItem(shop_item_id) {
      axios
        .delete(`/shop_item/${shop_item_id}`)
        .then(res => {
          if (res.status === 204) {
            //this.shop_items = this.shop_items.filter(item => item.shop_item_id !== shop_item_id);

            this.creating = false;
            this.editing = false;

            this.$emit('onDeleteShopItem', shop_item_id);

            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SHOPITEM.DELETED'));
          } else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SHOPITEM.UNABLE_DELETE'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SHOPITEM.UNABLE_DELETE'));
        });
    },
    selectShopItem(shop_item_id) {

      axios
        .get(`/shop_item/${shop_item_id}`)
        .then(res => {
          this.form = res.data;

          if (this.form.is_days === null) {
            this.form.is_days = false;
          }

          this.check_join[1] = false;
          this.check_join[0] = false;

          if (this.form.rules) {
            if (this.form.rules.age_max) this.check_join[1] = true;
            if (this.form.rules.age_min) this.check_join[0] = true;
          } else {
            this.form.rules = { age_min: null, age_max: null };
          }

          this.creating = false;
          this.editing = true;

          if (this.form.period_ids === null || this.form.period_ids === undefined) {
            this.form.period_ids = [];
          }

          this.calc_amount_vat();

          this.scrollToEditShopItem();

          if (this.form.period_type === 'PERIODS') {
            this.setPeriodsTable(this.form.period_ids);
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SHOPITEM.UNABLE_GET'));
        });
    },

    setPeriodsTable(period_ids) {

      this.$nextTick(() => {
        this.$refs['periodsTable'].setSelectedPeriodIds(period_ids);
      });

    },

    scrollToEditShopItem() {

    },

    scrollToClassName(classname) {
      this.$nextTick(() => {
        const el = this.$el.getElementsByClassName(classname)[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      });
    },

    selectedPeriodIdsChanged(period_ids) {
      this.form.period_ids = period_ids;
    },

    fixFloat(str) {
      const s = str + '';

      if (s === '') { return 0.0; }
      const r = parseFloat(s);

      if (isNaN(r)) {
        return 0.0;
      }

      return r;
    },
    fixIntWithDefault(str, val) {
      const s = str + '';

      if (str === '') { return val; }

      const r = parseInt(s);

      if (isNaN(r)) {
        return val;
      }

      return r;
    },

    async save_data() {
      await this.updateShopItem(undefined);
    },

    async updateShopItem(e) {
      if (e) {
        e.preventDefault();
      }

      const valid = await this.v$.$validate();

      if (!valid) {
        this.$refs['save-button'].stop();
        this.v$.$touch()
        return
      }

      const loader = this.$loading.show();

      try {
        
        this.form.amount = this.fixFloat(this.form.amount);
        this.form.amount_vat = this.fixFloat(this.form.amount_vat);

        this.form.vat_pct = this.fixIntWithDefault(this.form.vat_pct, 0);
        this.form.book_acct_vat = this.fixIntWithDefault(this.form.book_acct_vat, 0);
        this.form.book_acct_article = this.fixIntWithDefault(this.form.book_acct_article, 0);

        this.form.next_shop_item_id = this.fixIntWithDefault(this.form.next_shop_item_id, null);
        this.form.priority = this.fixIntWithDefault(this.form.priority, 0);
        this.form.num_days = this.fixIntWithDefault(this.form.num_days, 0);

        this.form.frontend.reg_only = true;
        this.form.company_id = this.currentCompanyId;
        this.form.period_id = this.currentPeriodId;

        if (!this.form.currency || this.form.currency === '') {
          this.form.currency = 'SEK';
        }

        if (this.creating) {
          try {
            const res = await axios.post('/shop_item', this.form);

            loader && loader.hide();

            if (res.status === 201) {
              this.creating = false;
              this.form = res.data;

              this.$emit('onShopItemCreated', this.form);

              this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SHOPITEM.CREATED'));
            }
            else {
              this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SHOPITEM.UNABLE_CREATE'));
            }
          }
          catch (err) {
            console.error('error creating shop item', err);
            loader && loader.hide();
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SHOPITEM.UNABLE_CREATE'));
          }

        } else {

          try {
            const res = await axios.put(`/shop_item/${this.form.shop_item_id}`, this.form);

            loader && loader.hide();

            if (res.status === 200) {
              this.creating = false;
              this.form = res.data;
              this.$emit('onShopItemUpdated', this.form);

              this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SHOPITEM.UPDATED'));
            }
            else {
              this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SHOPITEM.UNABLE_UPDATE'));
            }
          }
          catch (err) {
            console.error('unable to update shop item', err);
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SHOPITEM.UNABLE_UPDATE'));
          }

        }
      }
      catch (err) {
        loader && loader.hide();
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SHOPITEM.UNABLE_UPDATE'));
      }

      this.$refs['save-button'].stop();
    },
    resetForm() {
      this.form = {
        shop_item_id: '',
        company_id: '',
        name: '',
        descr: '',
        amount: 0,
        amount_vat: 0,
        price_sek: 0,
        vat_sek: 0,
        frontend: {
          img_url: '',
          reg_only: 0
        },
        discounts: {
          pct: 0,
          amount: 0,
          reason: ''
        },
        meta: {
          num_periods: 0,
          is_member_fee: 0,
          member_fee: false,
          send_email_template_id: null,
          periods: []
        },
        book_acct_vat: 0,
        book_acct_article: 0,
        book_place: '',
        book_project: '',
        book_tags: [],
        rules: { age_min: 0, age_max: 0 },
        is_family: false,
        is_default: false,
        is_member_fee: false,
        is_ticket: this.is_ticket,
        is_active: true,
        receiver_type: 'MEMBER',
        quantity_type:'QUANTITY'
      };
    }
  }
};
</script>
