<template>
  <div id="page-events">
    <Confirm
      :title="$t('COMMON.CONFIRM')"
      :message="$t('PAGES.EVENTS.CONFIRM_DELETE')"
      ref="deleteEventConfirm"
      type="success"
      @confirm="onDeleteEventConfirm"
    />

    <MemberEditorCompactModal
      ref="member-editor-compact-modal-tab"
      class="mt-8"
      :member_id="member_id"

      @created="member_created"
      @updated="member_updated"
      @deleted="member_deleted"
    />

    <MemberEventEditorModal
      :event="form"
      :item="member_event"
      :events="events"
      ref="member-event-editor-modal"
      @updated="updated_member_event"
      @created="created_member_event"
    />

    <div class="scroll-to-top"></div>

      <v-tabs background-color="white" color="accent-4" left>
        <v-tab>{{$t('PAGES.EVENTS.EVENTS')}}</v-tab>
        <v-tab>{{$t('PAGES.EVENTS.CATEGORIES')}}</v-tab>
        <v-tab>{{$t('COMMON.SETTINGS')}}</v-tab>

        <v-tab-item>

        <EventsTable
          :items="events"
          @showHistoryToggled="showHistoryToggled"
          @createEventClicked="createEvent"
          @publishEventClicked="publishEventClicked"
          @selectEventClicked="selectEvent"
          @deleteEventClicked="deleteEventClicked"
          @toastr="toastr"
        />
        <div class="scroll-to-container"></div>
        <b-card

          title=""
          class="mb-2 edit-event-container"
          ref="edit-event"
          id="edit-event"
          hide-footer
          v-if="creating || editing"
        >
          <b-tabs
            v-model="activetab_href_variable"
            content-class="mt-3" lazy>
            <b-tab :title="$t('COMMON.SETTINGS')" active>
              <div>
                <b-form class="mt-8" @submit="saveEvent">
                  <b-form-group id="input-group-event_id" label="ID" label-for="input-event_id">
                    <b-form-input
                      id="input-event_id"
                      v-model="form.event_id"
                      type="text"
                      disabled
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group id="input-group-1" :label="$t('PAGES.EVENTS.EVENT_NAME')" label-for="input-1">
                    <b-form-input
                      id="input-1"
                      v-model="form.name"
                      type="text"
                      required
                      :placeholder="$t('PAGES.EVENTS.EVENT_NAME')"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group id="descr-group" label="Text" label-for="descr">
                    <tiptap-vuetify
                      data-app
                      v-model="form.descr_html"
                      :extensions="extensions"
                    />

                  </b-form-group>
                  <b-card>
                    <h4 class="mb-8">{{$t('PAGES.EVENTS.EVENT_TIMESTAMPS')}}</h4>

                    <b-row>
                      <b-col lg="6">
                        <memlist-datetime-picker
                          v-model="form.from_datetime"
                          class="mb-8"
                          :label="$t('PAGES.EVENTS.START_TIME')"
                        ></memlist-datetime-picker>
                      </b-col>
                      <b-col lg="6">
                        <memlist-datetime-picker
                          v-model="form.to_datetime"
                          class="mb-8"
                          :label="$t('PAGES.EVENTS.END_TIME')"
                        ></memlist-datetime-picker>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col lg="12">
                        <div class="d-flex align-items-center mb-8">
                          <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                            <input type="checkbox" v-model="form.use_deadline" @click="form.use_deadline = !form.use_deadline" />
                            <span></span>
                          </label>
                          <span class="ml-3 cursor-pointer">{{$t('PAGES.EVENTS.CLOSE_SIGNUP')}}</span>
                        </div>

                      </b-col>
                    </b-row>

                    <b-row v-if="form.use_deadline">
                      <b-col lg="12">
                        <memlist-datetime-picker
                          v-model="form.deadline_datetime"
                          class="mb-8"
                          :label="$t('PAGES.EVENTS.LAST_SIGNUP_DATETIME')"
                        ></memlist-datetime-picker>
                      </b-col>
                    </b-row>

                  </b-card>

                  <b-card class="mt-8">
                    <h4 class="mb-8">{{$t('PAGES.EVENTS.LOCATION_FOR_EVENT')}}</h4>

                    <b-form-group id="location-descr" :label="$t('PAGES.EVENTS.LOCATION_DESCRIPTION')" label-for="location-descr">
                        <b-form-input
                          id="location_descr"
                          v-model="form.location_descr"
                          placeholder="Bakom det röda huset"
                        ></b-form-input>
                      </b-form-group>

                    <b-form-group>
                      <div class="d-flex align-items-center">
                        <label class="radio radio-list radio-outline radio-success">
                          <input
                            type="radio"
                            name="location_setting"
                            v-model="form.location_setting"
                            value="NONE"
                          />
                          <span></span>
                        </label>
                        <span class="ml-3 cursor-pointer">{{$t('PAGES.EVENTS.ADDRESS_UNAVAILABLE')}}</span>
                      </div>
                    </b-form-group>

                    <b-form-group>
                      <div class="d-flex align-items-center">
                        <label class="radio radio-list radio-outline radio-success">
                          <input
                            type="radio"
                            name="location_setting"
                            v-model="form.location_setting"
                            value="ONLINE"
                          />
                          <span></span>
                        </label>
                        <span class="ml-3 cursor-pointer">{{$t('PAGES.EVENTS.ONLINE')}}</span>
                      </div>
                    </b-form-group>

                    <b-form-group>
                      <div class="d-flex align-items-center">
                        <label class="radio radio-list radio-outline radio-success">
                          <input
                            type="radio"
                            name="location_setting"
                            v-model="form.location_setting"
                            value="ADDRESS"
                          />
                          <span></span>
                        </label>
                        <span class="ml-3 cursor-pointer">{{$t('PAGES.EVENTS.STREET_AND_POST')}}</span>
                      </div>
                    </b-form-group>


                    <b-form-group>
                      <div class="d-flex align-items-center">
                        <label class="radio radio-list radio-outline radio-success">
                          <input
                            type="radio"
                            name="location_setting"
                            v-model="form.location_setting"
                            value="GEO"
                          />
                          <span></span>
                        </label>
                        <span class="ml-3 cursor-pointer">{{$t('PAGES.EVENTS.GEO_COORDS')}}</span>
                      </div>
                    </b-form-group>

                    <!-- <div v-if="online">
                        <b-form-group id="main_url-group" label="Evenemangslänk (visas när evenemanget har startats)" label-for="main_url">
                        <b-form-input
                            id="main_url"
                            v-model="form.main_url"
                            placeholder=""
                        ></b-form-input>
                        </b-form-group>
                      </div> -->

                    <div v-if="form.location_setting === 'GEO'">
                      <b-row>
                        <b-form-group class="col-6" id="lat-group" label="Latitud" label-for="lat">
                          <b-form-input
                            id="lat"
                            v-model="edit_lat"
                            placeholder="123.3333"
                          ></b-form-input>
                          <b-form-text>{{$t('PAGES.EVENTS.EXAMPLE_LNG')}}</b-form-text>
                        </b-form-group>
                        <b-form-group class="col-6" id="lng-group" label="Longitud" label-for="lng">
                          <b-form-input
                            id="lng"
                            v-model="edit_lng"
                            placeholder="96.123"
                          ></b-form-input>
                          <b-form-text>{{$t('PAGES.EVENTS.EXAMPLE_LNG')}}</b-form-text>
                        </b-form-group>
                      </b-row>
                    </div>

                    <div v-if="form.location_setting === 'ADDRESS'">
                      <b-form-group
                        id="address-group"
                        :label="$t('PAGES.EVENTS.ADDRESS')"
                        label-for="address">

                        <b-form-input
                          id="address"
                          v-model="form.address"
                          :placeholder="$t('PAGES.EVENTS.ADDRESS')"
                        ></b-form-input>
                      </b-form-group>

                      <b-row>
                        <b-form-group
                          class="col-6"
                          id="zipcode-group"
                          :label="$t('PAGES.EVENTS.ZIPCODE')"
                          label-for="zipcode"
                        >
                          <b-form-input
                            id="zipcode"
                            v-model="form.zipcode"
                            :placeholder="$t('PAGES.EVENTS.ZIPCODE')"
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group
                          class="col-6"
                          id="postaladdress-group"
                          :label="$t('PAGES.EVENTS.POSTAL_ADDRESS')"
                          label-for="postaladdress"
                        >
                          <b-form-input
                            id="postaladdress"
                            v-model="form.postaladdress"
                            :placeholder="$t('PAGES.EVENTS.POSTAL_ADDRESS')"
                          ></b-form-input>
                        </b-form-group>
                      </b-row>
                    </div>
                    <div v-if="form.type === 'PHY'">

                      <b-alert v-if="!hasGoogleMaps" show variant="danger"
                        >{{$t('PAGES.EVENTS.GENERATE_GOOGLE_MAPS_ALERT')}}</b-alert
                      >

                      <b-row class="mb-8">
                        <b-col lg="4">
                          <b-button type="button" variant="primary" @click="generateGoogleClicked"
                            >{{$t('PAGES.EVENTS.GENERATE_GOOGLE_MAPS')}}</b-button
                          >
                        </b-col>
                      </b-row>


                      <b-row class="mb-8">
                        <b-col lg="12">
                          <google-map
                            v-if="form.google_maps && hasGoogleMaps"
                            :center="{ lat: form.google_maps.lat, lng: form.google_maps.lng }"
                            :zoom="16"
                            style="height: 256px"
                            id="map"
                            ref="Map"
                          >
                            <google-map-marker
                              :position="{ lat: form.google_maps.lat, lng: form.google_maps.lng }"
                            ></google-map-marker>

                            <google-map-infowindow
                              :position="infoWIndowContext.position"
                              :show.sync="showInfo"
                              :options="{ maxWidth: 300 }"
                            >
                              <h4>{{ infoWIndowContext.title }}</h4>
                              <p>{{ infoWIndowContext.description }}</p>
                            </google-map-infowindow>
                          </google-map>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card>

                  <b-card class="mt-8">
                    <div
                      v-b-toggle.collapse_url_group
                      class="d-flex justify-content-between w-100 bg-white"
                      @click="collapse_url_group = !collapse_url_group"
                    >
                      <h4>{{$t('PAGES.EVENTS.PUBLISH_OPTIONS')}}</h4>
                      <b-button variant="primary" class="collapse_button">
                        {{ collapse_url_group ? $t('COMMON.HIDE') : $t('COMMON.SHOW') }}
                      </b-button>
                    </div>
                    <b-collapse id="collapse_url_group" class="mt-4">
                      <b-form-group
                        id="facebook_url-group"
                        :label="$t('PAGES.EVENTS.FACEBOOK_URL')"
                        label-for="facebook_url"
                      >
                        <b-form-input
                          id="facebook_url"
                          v-model="form.facebook_url"
                          :placeholder="$t('PAGES.EVENTS.FACEBOOK_URL')"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group id="extern_url-group" :label="$t('PAGES.EVENTS.OTHER_LINK')" label-for="extern_url">
                        <b-form-input
                          id="extern_url"
                          v-model="form.extern_url"
                          :placeholder="$t('PAGES.EVENTS.OTHER_LINK')"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        id="category_id"
                        :label="$t('PAGES.EVENTS.CATEGORY')"
                        label-for="category_id"
                        class="mt-8"
                      >
                        <b-form-select v-model="form.category_id" :options="event_categories_options"></b-form-select>
                      </b-form-group>
                    </b-collapse>


                  </b-card>

                  <b-card class="mt-8">
                    <div
                      v-b-toggle.collapse_book_vat_acct
                      class="d-flex justify-content-between w-100 bg-white"
                      @click="collapse_book_vat_acct = !collapse_book_vat_acct"
                    >
                      <h4>{{$t('PAGES.EVENTS.ACCOUNTING')}}</h4>
                      <b-button variant="primary" class="collapse_button">
                        {{ collapse_book_vat_acct ? $t('COMMON.HIDE') : $t('COMMON.SHOW') }}
                      </b-button>
                    </div>

                    <b-collapse id="collapse_book_vat_acct" class="mt-4">
                      <b-form-group id="book_vat_acct" :label="$t('PAGES.EVENTS.VAT_ACCOUNT')" label-for="book_vat_acct">
                        <b-form-input id="book_vat_acct" v-model="form.book_vat_acct"></b-form-input>
                      </b-form-group>

                      <b-form-group
                        id="book_cost_center"
                        :label="$t('PAGES.EVENTS.COST_CENTER')"
                        label-for="book_cost_center"
                      >
                        <b-form-input
                          id="book_cost_center"
                          v-model="form.book_cost_center"
                        ></b-form-input>
                      </b-form-group>

                    </b-collapse>
                  </b-card>

                  <b-card class="mt-8">
                    <h4 class="mb-8">{{$t('PAGES.EVENTS.COMMUNICATION')}}</h4>

                    <b-form-group
                      id="ticket_template_id-group"
                      :label="$t('PAGES.EVENTS.EMAIL_TEMPLATE')"
                      label-for="ticket_template_id"
                    >
                      <b-form-select
                        id="ticket_template_id"
                        v-model="form.ticket_template_id"
                        :options="emailTemplatesOptions"
                        :state="valid_ticket_email_template"
                      ></b-form-select>
                    </b-form-group>

                    <h5 class="mt-12">{{$t('PAGES.EVENTS.SEND_EMAIL')}}</h5>

                    <AddUserList
                      class="mt-4"
                      ref="notifUserList"
                      :notif_user_ids="form.notif_user_ids"
                      @onNotifEmailsSelected="onNotifEmailsSelected"
                    />

                    <!--<b-form-group id="signedup_template_id-group" label="E-post mall för bekräftelse" label-for="ticket_template_id">
                      <b-form-select
                          id="signedup_template_id"
                          v-model="form.signedup_template_id"
                          :options="emailTemplatesOptions"
                      ></b-form-select>
                      </b-form-group>-->
                  </b-card>




                  <b-card class="mt-8 mb-8">
                    <h4 class="mb-8">{{$t('PAGES.EVENTS.SIGNUPS')}}</h4>


                    <b-form-group>
                      <div class="d-flex align-items-center">
                        <label class="radio radio-list radio-outline radio-success">
                          <input
                            type="radio"
                            name="open_setting"
                            v-model="form.open_setting"
                            value="PUBLIC"
                          />
                          <span></span>
                        </label>
                        <span class="ml-3 cursor-pointer">{{$t('PAGES.EVENTS.OPEN_FOR_ALL')}}</span>
                      </div>
                    </b-form-group>

                    <b-form-group>
                      <div class="d-flex align-items-center">
                        <label class="radio radio-list radio-outline radio-success">
                          <input
                            type="radio"
                            name="open_setting"
                            v-model="form.open_setting"
                            value="ANY_MEMBER"
                          />
                          <span></span>
                        </label>
                        <span class="ml-3 cursor-pointer">{{$t('PAGES.EVENTS.ANY_MEMBER')}}</span>
                      </div>
                    </b-form-group>

                    <b-form-group>
                      <div class="d-flex align-items-center">
                        <label class="radio radio-list radio-outline radio-success">
                          <input
                            type="radio"
                            name="open_setting"
                            v-model="form.open_setting"
                            value="COMPANY_MEMBER_PAID"
                          />
                          <span></span>
                        </label>
                        <span class="ml-3 cursor-pointer">{{$t('PAGES.EVENTS.COMPANY_MEMBER_PAID')}}</span>
                      </div>
                    </b-form-group>

                    <b-form-group>
                      <div class="d-flex align-items-center">
                        <label class="radio radio-list radio-outline radio-success">
                          <input
                            type="radio"
                            name="open_setting"
                            v-model="form.open_setting"
                            value="ANY_MEMBER_PAID"
                          />
                          <span></span>
                        </label>
                        <span class="ml-3 cursor-pointer">{{$t('PAGES.EVENTS.ANY_MEMBER_PAID')}}</span>
                      </div>
                    </b-form-group>

                    <b-form-group
                      id="num_seats"
                      :label="$t('PAGES.EVENTS.MAX_SIGNUPS')"
                      label-for="num_seats">
                      <b-form-input
                        id="num_seats"

                        v-model="form.num_seats"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      id="max_tickets_per"
                      :label="$t('PAGES.EVENTS.MAX_TICKETS_PER_PERSON')"
                      label-for="max_tickets_per">
                      <b-form-input
                        id="max_tickets_per"

                        v-model="form.max_tickets_per"
                      ></b-form-input>
                    </b-form-group>

                    <h4>{{ $t('MEMBER_PROPERTY.QUESTIONEER') }}</h4>
                    <p style="font-style: italic;">{{ $t('MEMBER_PROPERTY.QUESTIONEER_INFO') }}</p>
                    <AttributeGroupSelectButton
                      :group_ids="form.group_ids"
                      @selected="property_groups_selected"
                    />
                    <p class="mt-4"><a href="/ml-properties">{{ $t('MEMBER_PROPERTY.ADMINISTER') }} >></a></p>
                  </b-card>

                  <b-card :title="$t('PAGES.EVENTS.TERMS')" class="mb-8">
                    <div class="d-flex align-items-center mb-4">
                      <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                        <input type="checkbox" v-model="form.has_terms" @click="form.has_terms = !form.has_terms" />
                        <span></span>
                      </label>
                      <span class="ml-3 cursor-pointer">{{$t('PAGES.EVENTS.MUST_ACCEPT')}}</span>
                    </div>

                    <b-form-group v-if="form.has_terms" id="terms" :label="$t('PAGES.EVENTS.TERMS_TEXT')" label-for="terms">
                      <tiptap-vuetify
                        data-app
                        v-model="form.terms"
                        :extensions="extensions"
                      />
                      
                    </b-form-group>

                  </b-card>

                  <div class="ml-8">

                    <h4>{{ $t('EVENT.VAT_OPTION') }}</h4>

                    <b-form-group class="mt-6">
                      <div class="d-flex align-items-center">
                        <label class="radio radio-list radio-outline radio-success">
                          <input
                            type="radio"
                            name="vat_option"
                            v-model="form.vat_option"
                            value="INC_VAT"
                          />
                          <span></span>
                        </label>
                        <span class="ml-3 cursor-pointer">{{$t('EVENT.VAT_OPTIONS.INC_VAT')}}</span>
                      </div>
                    </b-form-group>

                    <b-form-group>
                      <div class="d-flex align-items-center">
                        <label class="radio radio-list radio-outline radio-success">
                          <input
                            type="radio"
                            name="vat_option"
                            v-model="form.vat_option"
                            value="EX_VAT"
                          />
                          <span></span>
                        </label>
                        <span class="ml-3 cursor-pointer">{{$t('EVENT.VAT_OPTIONS.EX_VAT')}}</span>
                      </div>
                    </b-form-group>

                  </div>

                  
                  <EventTickets
                    ref="eventTicketsComponent"
                    :event="form"
                  />

                  <div v-if="false">
                    <div class="d-flex align-items-center mb-4">
                      <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                        <input
                          type="checkbox"
                          name=""
                          v-model="form.strike_system"
                          @click="form.strike_system = !form.strike_system"
                        />
                        <span></span>
                      </label>
                      <span class="ml-3 cursor-pointer">{{$t('PAGES.EVENTS.STRIKE_WARN')}}</span>
                    </div>

                    <b-form-group
                      v-if="form.strike_system"
                      id="strike_template_id-group"
                      :label="$t('PAGES.EVENTS.EMAIL_TEMPLATE_WARN')"
                      label-for="strike_template_id"
                    >
                      <b-form-select
                        id="strike_template_id"
                        v-model="form.strike_template_id"
                        :options="emailTemplatesOptions"
                      ></b-form-select>
                    </b-form-group>

                    <b-form-group
                      v-if="form.strike_system"
                      id="num_strike_days-group"
                      :label="$t('PAGES.EVENTS.BAN_FOR_NUM_DAYS')"
                      label-for="num_strike_days"
                    >
                      <b-form-input id="num_strike_days" v-model="form.num_strike_days"></b-form-input>
                    </b-form-group>

                    <b-form-group
                      id="num_strikes_max-group"
                      :label="$t('PAGES.EVENTS.PERM_BAN_AFTER_NUM_WARN')"
                      label-for="num_strikes_max"
                    >
                      <b-form-input
                        id="num_strikes_max"

                        v-model="form.num_strikes_max"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      id="num_strikes_orgs-group"
                      :label="$t('PAGES.EVENTS.BAN_ORGS_WITH_NUM_MEMBERS_BANNED')"
                      label-for="num_strikes_orgs"
                    >
                      <b-form-input
                        id="num_strikes_orgs"
                        v-model="form.num_strikes_orgs"
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <b-card v-if="form.type === 'PHY' && !hasGoogleMaps" class="mt-8 mb-8">
                      <b-row>
                        <b-col lg="9">
                          <b-alert show variant="danger" class="flex-fill mr-2"
                          >
                          {{$t('PAGES.EVENTS.IRL_EVENT_TEXT')}}
                          </b-alert
                          >
                        </b-col>
                        <b-col lg="3">
                          <b-button
                          type="button"
                          variant="primary"
                          @click="generateGoogleClicked"
                          style="height:40px;"
                          >
                          {{$t('PAGES.EVENTS.GENERATE_GOOGLE_MAPS')}}
                          </b-button
                        >
                        </b-col>
                      </b-row>
                  </b-card>

                  <!--<b-alert v-if="!form.members_only && !form.public_only" show variant="danger">Du måste välja ansökningssätt</b-alert>-->
                  <b-row>
                    <b-col>

                      <RightSaveButton
                        :text="$t('PAGES.EVENTS.SAVE_EVENT')"
                        :spin="true"
                        @clicked="on_submit"
                        @close="$emit('cancel')"
                        ref="saveButton"
                      />
                    </b-col>
                  </b-row>

                </b-form>
              </div>
            </b-tab>
            <b-tab :title="$t('PAGES.EVENTS.SIGNUPED')">
              <b-row class="mb-4">
                <b-col>
                  <div class="w-100 mr-md-10 mb-md-0 mb-4">
                    <b-form-input
                      id="filter-input"
                      v-model="textFilter"
                      type="search"
                      style="max-width:621px"
                      :placeholder="$t('PAGES.EVENTS.SEARCH_NAME')"
                    ></b-form-input>
                </div>

                </b-col>

                <b-col>
                  <div class="d-md-flex justify-content-end align-items-end mb-4">
                    <a
                      href="#"
                      class="btn btn-primary font-weight-bolder font-size-sm mr-6"
                      @click.prevent="createMemberEventClicked"
                      ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('PAGES.EVENTS.ADD_PARTICIPANT')}}</a
                    >

                    <a
                      href="#"
                      class="btn btn-primary font-weight-bolder font-size-sm mr-6"
                      @click.prevent="exportXlsxClicked"
                      ><i class="menu-icon flaticon2-file" style="font-size: 1.0em;"></i>{{$t('COMMON.EXPORT_XLSX')}}</a
                    >
                  </div>
                  
                </b-col>
                
                
              </b-row>


              <b-table
                :items="member_events"
                :fields="event_headers"
                sticky-header
                class="table-striped table-reponsive">
                <template #cell(hantera)="row">

                  <a v-if="row.item.member" class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="member_selected(row.item.member.member_id)">
                    <span class="svg-icon svg-icon-md text-primary">
                      <i class="fa fa-user color-primary"></i>
                    </span>
                  </a>


                  <a class="btn btn-icon btn-light btn-sm mx-1" @click="edit_member_event_clicked(row.item)">
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg src="/assets/svg/Write.svg"></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>

                  <a
                    href="#"
                    class="btn btn-icon btn-light btn-sm mx-3"
                    @click="delete_member_event_clicked(row.item)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
                    </span>
                  </a>
                </template>
              </b-table>
            </b-tab>
            <Confirm
              :title="$t('PAGES.EVENTS.REMOVE_PARTICIPANT')"
              :message="$t('PAGES.EVENTS.REMOVE_PARTICIPANT_TEXT')"
              ref="delete-member-event"
              :type="'success'"
              :confirm="$t('COMMON.YES')"
              :cancel="$t('COMMON.NO')"
              @confirm="delete_member_event"
            />

          </b-tabs>
        </b-card>
        </v-tab-item>
        <v-tab-item>
          <EventCategory
            class="mt-8"
            @eventCategoryDeleted="eventCategoryDeleted"
            @eventCategoryCreated="eventCategoryCreated"
          />
        </v-tab-item>
        <v-tab-item>
          <EventSettings />
        </v-tab-item>
      </v-tabs>

  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";

:deep .card-body {
  @media (max-width: 1024px) {
    padding: 1rem 0rem !important;
  }
}

:deep input {
  @media (max-width: 1024px) {
    height: 55px;
    font-size: 18px;
  }
}

.edit-event-container {
  @media (max-width: 1024px) {
    padding: 4px;
  }
}

.bg-white {
  background-color: white;
}
.collapse_button {
  width: 80px;
}

.date-time-picker {
  font-family: Poppins, Helvetica, 'sans-serif' !important;
}
.b-table-sticky-header{
  overflow-y: hidden;
  max-height: unset;
}

</style>

<script>
import { TiptapVuetify, Image, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify';
import axios from 'axios';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import AddUserList from '@/view/components/AddUserList.vue';
import EventsTable from '@/view/pages/ml/events/EventsTable.vue';
import EventTickets from '@/view/pages/ml/events/EventTickets.vue';

import EventCategory from '@/view/pages/ml/event_category/EventCategory.vue';
import EventSettings from '@/view/pages/ml/events/EventSettings.vue';
import Companies from './Companies.vue';
import Confirm from '@/view/components/Confirm.vue';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import MemberEditorCompactModal from '@/view/components/member_editor_v2/MemberEditorCompactModal.vue';
import MemberEventEditorModal from '@/view/pages/ml/events/MemberEventEditorModal.vue';


import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';
import AttributeGroupSelectButton from '@/view/components/attribute_group_selector/AttributeGroupSelectButton.vue';

export default {
  name: 'events',
  components: {
    MemberEditorCompactModal,
    EventsTable,
    TiptapVuetify,
    Companies,
    Confirm,
    EventTickets,
    MemberEventEditorModal,
    EventCategory,
    AddUserList,
    EventSettings,
    RightSaveButton,
    AttributeGroupSelectButton
  },
  emits: ['cancel'],
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['companies', 'currentCompanyId', 'currentPeriodId']),

    valid_ticket_email_template() {
      return this.form.ticket_template_id !== null;
    }
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      extensions: [
        Image,
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
      member_event: {},
      member_id: null,
      activetab_href_variable: 0,
      showMemberEventEditor: false,
      member_event_id: null,
      event_categories_options: [],
      edit_lng: null,
      edit_lat: null,
      member_events_copy: null,
      textFilter: null,
      toDelete: null,
      memberEventToDelete: null,
      memberEvent: null,

      showHistory: false,
      hasGoogleMaps: false,
      cost_group_member: false,
      collapse_book_vat_acct: false,
      collapse_url_group: false,
      form: {
        vat_option: 'INC_VAT',
        group_ids: [],
        use_deadline: false,
        location_descr: null,
        location_setting: 'NONE',
        max_tickets_per: 1,
        event_id: null,
        num_strikes_orgs: 9999999,
        num_strikes_max: 9999999,
        num_seats: 0,
        num_signups: 0,
        num_strike_days: 0,
        strike_template_id: '',
        strike_system: false,
        members_only: true,
        public_only: false,
        sms_template_id: '',
        send_sms_invite: false,
        email_template_id: '',
        send_email_invite: false,
        signedup_template_id: '',
        ticket_template_id: '',
        cost_non_members: 0,
        cost: 0,
        extern_url: '',
        facebook_url: '',
        to_datetime: '',
        from_datetime: '',
        postaladdress: '',
        zipcode: '',
        address: '',
        descr: '',
        name: '',
        type: 'NONE',
        book_acct_first: 0,
        book_acct_second: 0,
        google_maps: { lat: 0, lng: 0, static_link: '' },
        book_tags: [],
        book_acct: '',
        book_vat_acct: '',
        book_cost_center: '',
        notif_user_ids: [],
        open_setting: 'ANY_MEMBER',
      },
      clients_forevent: [],
      member_events: [],
      creating: false,
      editing: false,
      templates: [],
      emailTemplatesOptions: [],
      smsTemplatesOptions: [],
      addressok: false,
      zipcodeok: false,
      postaladdressok: false,
      generateGoogle: false,
      showInfo: false,
      events: [],
      event_headers: [
        {
          key: 'ts_signup',
          label: this.$t('COMMON.DATE'),
          sortable: true,
          formatter: (_, __, item) => {
            return item.ts_signup;
          },
        },
        {
          key: 'company',
          label: this.$t('MEMBER.COMPANY_NAME')
        },
        {
          key: 'shop_order_id',
          label: this.$t('SHOP_ORDER.PAY_REF'),
          formatter: (_, __, item) => {
            return item.shop_order_id;
          },
        },
        {
          key: 'checkedin',
          label: this.$t('PAGES.EVENTS.CHECKEDIN'),
          sortable: false,
          formatter: (_, __, item) => {
            return item.checkedin ? this.$t('COMMON.YES') : this.$t('COMMON.NO')
          },
        },
        {
          key: 'firstname',
          label: this.$t('COMMON.NAME'),
          formatter: (_, __, item) => {

            const m = item.person !== null && item.person !== undefined ? item.person : item.member;

            if (m.cname) {
              return m.cname + ' ' + m.firstname + ' ' + m.lastname;
            }

            return m.firstname + ' ' + m.lastname;
          },
          sortable: false
        },
        {
          key: 'email',
          label: this.$t('MEMBER.EMAIL'),
          formatter: (_, __, item) => {

            const m = item.person !== null && item.person !== undefined ? item.person : item.member;

            if (m.email) {
              return m.email;
            }

            return '';
          },
        },
        {
          key: 'telefon',
          label: this.$t('MEMBER.MOBILE'),
          formatter: (_, __, item) => {

            const m = item.person !== null && item.person !== undefined ? item.person : item.member;

            if (m.phone) {
              return m.phone;
            }

            return '';
          },
        },
        {
          key: 'hantera',
          label: this.$t('COMMON.ACTIONS')
        }
      ],

      infoWIndowContext: {
        position: {
          lat: 44.2899,
          lng: 11.8774
        }
      },
      infoWindowsList: []
    };
  },
  watch: {
    'edit_lat'(newValue) {
      if (!newValue) {
        return;
      }
      const lat = parseFloat((newValue+'').replace(/,/g, '.'));

      if (isNaN(lat)) {
        console.error('nan');
      }
      else {
        this.form.google_maps.lat = lat;
      }
    },
    'edit_lng'(newValue) {
      if (!newValue) {
        return;
      }

      const lng = parseFloat((newValue+'').replace(/,/g, '.'));

      if (isNaN(lng)) {
        console.error('nan');
      }
      else {
        this.form.google_maps.lng = lng;
      }
    },
    'form.location_setting'(newValue) {
      if (newValue === 'NONE') { this.form.type = 'NONE'; }
      else if (newValue === 'ONLINE') { this.form.type = 'ONL'; }
      else { this.form.type = 'PHY'; }
    },
    textFilter(newValue) {
      if (newValue && newValue.length < 3) {
        this.member_events = this.member_events_copy;

        return;
      }

      this.member_events = this.member_events_copy.filter(item => item.search.indexOf(newValue) >= 0);

    },

    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        // close event editor
        this.editing = false;
        this.creating = false;
        // load company specific data
        this.loadEvents();
        this.loadTemplates();
        // scroll to start of page
        this.scrollToClassName('scroll-to-top');
      }
    }
  },
  methods: {
    member_created(member) {},
    member_updated(member) {},
    member_deleted(member_id) {},

    member_selected(member_id) {
      this.member_id = member_id;
      this.$refs['member-editor-compact-modal-tab'].show(member_id);
    },

    property_groups_selected(group_ids) {
      this.form.group_ids = group_ids;
      this.form = { ...this.form }

      console.log('groups selected', group_ids);
    },

    on_submit() {

      this.saveEvent();
    },

    onNotifEmailsSelected(notif_user_ids) {
      this.form.notif_user_ids = notif_user_ids;
    },
    updated_member_event(member_event) {
      const index = this.member_events.findIndex(item => item.member_event_id === member_event.member_event_id);

      if (index >= 0) {
        this.member_events[index] = member_event;
        this.member_events = [ ...this.member_events ];
      }

      // when event was changed
      if (this.form.event_id !== member_event.event_id) {
        this.member_events.splice(index, 1);
        this.member_events = [ ...this.member_events ];
      }

    },
    created_member_event(memberEvent) {

      this.member_events.push({ ...memberEvent,
        id: memberEvent.member_event_id,
        ts_signup: memberEvent.ts_signup,
        shop_order_id: memberEvent.shop_order_id,
        firstname: memberEvent.member ? memberEvent.member.firstname : memberEvent.person.firstname,
        lastname: memberEvent.member ? memberEvent.member.lastname : memberEvent.person.lastname,
        email: memberEvent.member ? memberEvent.member.email : memberEvent.person.email,
        telefon: memberEvent.member ? memberEvent.member.phone : memberEvent.person.phone,
        hantera: ''
      } );

      this.member_events = [ ...this.member_events ]

      this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.EVENTS.MEMBER_PERSON_ADDED'));
    },
    eventCategoryDeleted(id) {
      this.event_categories_options = this.event_categories_options.filter(item => item.id !== id);
    },
    eventCategoryCreated(event_category) {
      this.event_categories_options.push({
        value: event_category.id,
        text: event_category.name
      });
    },

    createMemberEventClicked() {
      this.showMemberEventEditor = true;
      this.member_event = {};

      this.$refs['member-event-editor-modal'].show();

      this.$nextTick(() => {
        this.scrollToClassName('member-event-editor');
      });
    },

    exportXlsxClicked() {
      axios
        .post(`/member_event/xlsx/${this.form.event_id}`, {})
        .then(res => {
          if (res.status === 201) {
            downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);
          }
          else if (res.status === 403) {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.LACK_PERMISSION'));
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.UNABLE_CREATE_FILE'));
          }
        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.UNABLE_CREATE_FILE'));
          console.error(err);
        });
    },

    async edit_member_event_clicked(member_event) {

      this.member_event = member_event;
      this.member_event_id = member_event.member_event_id;

      try {
        const res = await axios.get(`/member_event/${member_event.member_event_id}`);

        if (res.status === 200) {
          this.member_event = res.data;
          this.member_event_id = this.member_event.member_event_id;

          this.$refs['member-event-editor-modal'].show();

          return;
        }
      }
      catch (err) {
        console.error('load member event error', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENTS.UNABLE_LOAD_MEMBER_EVENT'));
    },
    delete_member_event_clicked(memberEvent) {
      this.memberEventToDelete = memberEvent;
      this.$refs['delete-member-event'].show();
    },
    showHistoryToggled(show) {
      this.showHistory = show;

      this.loadEvents();
    },
    generateGoogleClicked() {

      if (this.form.location_setting === 'ADDRESS') {
        if (!this.form.address || !this.form.zipcode || !this.form.postaladdress) {
          this.toastr('warning', 'Google maps', this.$t('PAGES.EVENTS.MUST_FILL_ADDRESS'));
          return;
        }

        this.generateGoogleMaps(this.form.address, this.form.zipcode, this.form.postaladdress);
      }
      else if (this.form.location_setting === 'GEO') {
        this.generateGoogleMapsFromLatLng(this.form.google_maps.lat, this.form.google_maps.lng);
      }

    },
    generateGoogleMapsFromLatLng(lat, lng) {
      axios
        .get(`/company/geolatlng?lat=${lat}&lng=${lng}`)
        .then(res => {
          if (res.status === 200) {
            this.toastr('success', 'Google Maps', this.$t('PAGES.EVENTS.SUCCESS_GOOGLE_MAPS'));

            this.form.google_maps = {
              lat: res.data.lat,
              lng: res.data.lng,
              static_link: ''
            };

            this.edit_lat = this.form.google_maps.lat;
            this.edit_lng = this.form.google_maps.lng;

            this.hasGoogleMaps = true;

            if (this.editing && this.form.ticket_template_id) {
              this.saveEvent();
            }

          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENTS.UNABLE_GOOGLE_MAPS'));
        });
    },

    generateGoogleMaps(addr, zipcode, postal) {
      axios
        .get(`/company/geo?address=${addr}&zipcode=${zipcode}&postaladdress=${postal}&country=SE`)
        .then(res => {
          if (res.status === 200) {
            this.toastr('success', 'Google Maps', this.$t('PAGES.EVENTS.SUCCESS_GOOGLE_MAPS'));

            this.form.google_maps = {
              lat: res.data.lat,
              lng: res.data.lng,
              static_link: ''
            };

            this.edit_lat = this.form.google_maps.lat;
            this.edit_lng = this.form.google_maps.lng;

            this.hasGoogleMaps = true;

            if (this.editing ) {
              this.saveEvent();
            }

          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', 'Google Maps', this.$t('PAGES.EVENTS.UNABLE_GOOGLE_MAPS'));
        });
    },
    async loadData() {

      if (!this.currentCompanyId) {
        return;
      }

      this.loadTemplates();
      this.loadEvents();
      this.loadEventCategories();
    },
    createEvent() {
      this.creating = true;
      this.editing = true;
      axios
        .post('/events', {
          name: this.$t('PAGES.EVENTS.NEW_EVENT'),
          company_id: this.currentCompanyId,
          members_only: true,
          public_only: false,
          type: 'NONE'
        })
        .then(res => {
          if (res.status == 201) {
            this.events.push(res.data);
            this.form = res.data;
            this.selectEvent(res.data.event_id);
            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.EVENTS.CREATED_EMPTY'));
          } else {
            console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENTS.UNABLE_CREATE'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENTS.UNABLE_CREATE'));
        });
    },
    deleteEventClicked(event_id) {
      this.toDelete = event_id;
      this.$refs['deleteEventConfirm'].show();
    },
    onDeleteEventConfirm() {
      this.deleteEvent(this.toDelete);
      this.toDelete = null;
    },
    deleteEvent(event_id) {
      axios
        .delete(`/events/${event_id}&company_id=${this.currentCompanyId}`)
        .then(res => {
          if (res.status === 204) {
            this.events = this.events.filter(item => item.event_id !== event_id);

            this.creating = false;
            this.editing = false;

            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.EVENTS.DELETED'));
          } else if (res.status === 409) {
            this.toastr(
              'danger',
              this.$t('COMMON.ERROR'),
              this.$t('PAGES.EVENTS.UNABLE_DELETE_CONFLICT')
            );
          } else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENTS.UNABLE_DELETE'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENTS.UNABLE_DELETE'));
        });
    },
    selectEvent(event_id) {
      this.activetab_href_variable = 0;

      this.form.descr_html = '';
      axios
        .get(`/events/${event_id}`)
        .then(res => {
          this.form = res.data;
          this.creating = false;
          this.editing = true;
          if (!this.form.members_only && !this.form.public_only) {
            this.form.members_only = true;
          }
          this.open_setting = this.form.members_only ? 'members' : 'public';
          if (!this.form.google_maps) {
            this.hasGoogleMaps = false;
            this.form.google_maps = { lat: 0, lng: 0, static_link: '' };
          } else {
            if (
              this.form.google_maps.lat !== 0 &&
              this.form.google_maps.lng !== 0 &&
              this.form.google_maps.lat !== '' &&
              this.form.google_maps.lng !== ''
            ) {
              this.hasGoogleMaps = true;
              this.edit_lat = this.form.google_maps.lat;
              this.edit_lng = this.form.google_maps.lng;
            } else {
              this.hasGoogleMaps = false;
            }
          }

          this.scrollToClassName('scroll-to-container');

          this.$nextTick(() => {
            this.$refs['eventTicketsComponent'].setEvent(this.form);
            this.$refs['notifUserList'].update();
          });


          axios
            .get(`/member_event/participants?event_id=${event_id}`)
            .then(res => {
              if (res.status === 200) {
                this.member_events = res.data;
                let company_set = false;

                this.member_events = this.member_events.filter(item => item.person !== null || item.member !== null);

                for (let i = 0; i < this.member_events.length; ++i) {

                  const m = this.member_events[i].person ? this.member_events[i].person : this.member_events[i].member;

                  if (!company_set && this.member_events[i].company) {
                    company_set = true;
                  }
                  this.member_events[i].search = (m.firstname+'').toLowerCase() + (m.lastname+'').toLowerCase() + m.personnr + m.in_personnr + m.phone + (m.email+'').toLowerCase();
                }

                if (!company_set) {
                  this.event_headers = this.event_headers.filter(item => item.key !== 'company')
                }

                this.member_events_copy = this.member_events;
              }
            })
            .catch(err => {
              console.error(err, 'clients for event');
            });
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENTS.UNABLE_GET_EVENT'));
        });
    },
    delete_member_event() {
      const member_event = this.memberEventToDelete;
      axios
        .delete(`/member_event/${member_event.member_event_id}`)
        .then(res => {
          if (res.status === 204) {
            this.member_events = this.member_events.filter(function(item) {
              return item.member_event_id !== member_event.member_event_id;
            });

            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.EVENTS.REMOVED_FROM_EVENT'));
            this.loadData();
          } else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENTS.UNABLE_REMOVE_PARTICIPANT'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENTS.UNABLE_REMOVE_PARTICIPANT'));
        });
    },
    publishEventClicked(event_id) {},
    getLangText(lang_text, lang) {
      for (var i = 0; i < lang_text.length; ++i) {
        if (lang_text[i].lang === lang) {
          return lang_text[i].text;
        }
      }

      return 'Språköversättning saknas';
    },
    async loadEvents() {
      const loader = this.$loading.show();
      this.events = [];
      try {
        const res = await axios.get(`/events/list?company_id=${this.currentCompanyId}`);
        if (res.status === 200) {
          var events = res.data;

          var now = dayjs();

          for (const event of events) {
            var eventdt = dayjs(event.to_datetime);
            if (!this.showHistory && eventdt < now) {
              continue;
            }

            //check event date
            this.events.push(event);
          }
        } else {
          console.error(res);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENTS.UNABLE_LIST'));
        }
      } catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENTS.UNABLE_LIST'));
      } finally {
        loader && loader.hide();
      }
    },
    loadTemplates() {
      this.templates = [];
      this.smsTemplatesOptions = [];
      this.emailTemplatesOptions = [];

      axios
        .get(`/template?company_id=${this.currentCompanyId}`)
        .then(res => {
          if (res.status === 200) {
            this.templates = res.data;
          }

          for (var i = 0; i < this.templates.length; ++i) {
            if (!this.templates[i].is_sms) {
              this.emailTemplatesOptions.push({
                value: this.templates[i].template_id,
                text: this.templates[i].name
              });
            } else {
              this.smsTemplatesOptions.push({
                value: this.templates[i].template_id,
                text: this.templates[i].name
              });
            }
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_LIST'));
        });
    },
    scrollToClassName(classname) {
      this.$nextTick(()=>{
        const el = this.$el.getElementsByClassName(classname)[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      });
    },
    checkUrl(url) {
      if (!url || url === '' || url === 'https://') return '';
      if (url.indexOf('http://') < 0 && url.indexOf('https://') < 0) return 'https://' + url;
      else return url;
    },
    async saveEvent(e) {
      if (e !== undefined) {
        e.preventDefault();
      }

      if (!this.form.ticket_template_id) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENTS.SELECT_TICKET_TEMPLATE'));
        this.$refs['saveButton'].stop();
        return;
      }

      try {
        this.form.company_id = this.currentCompanyId;
        this.form.facebook_url = this.checkUrl(this.form.facebook_url);
        this.form.extern_url = this.checkUrl(this.form.extern_url);
        this.form.max_tickets_per = parseInt(this.form.max_tickets_per+'');

        const res = await axios.put(`/events/${this.form.event_id}`, this.form)

        if (res.status === 200) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.EVENTS.UPDATED'));
          const index = this.events.findIndex(item => item.event_id === this.form.event_id);

          if (index >= 0) {

            this.events[index] = this.form;
            this.events = [ ...this.events ];
          }

        } else {
          console.error(res.status);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENTS.UNABLE_UPDATE'));
        }

      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENTS.UNABLE_UPDATE'));
      }

      this.$refs['saveButton'].stop();
    },
    publishEvent(e) {
      if (e !== undefined) {
        e.preventDefault();
      }
      this.editing = false;
      this.creating = false;
      this.scrollToClassName('scroll-to-top');
    },


    loadEventCategories() {
      axios
        .get(`/event_category`)
        .then(res => {
          if (res.status === 200) {

            this.event_categories_options = [];

            for (const ec of res.data) {
              this.event_categories_options.push({
                value: ec.id,
                text: ec.name
              });
            }
          }
        })
        .catch(err => {
          console.error(err);
        });
    },
  }
};
</script>
